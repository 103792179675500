<template>
    <div>
        <h5>{{ title }}</h5>
        <RowWrap width="auto" :class="[{ 'is-empty': mShifts.length === 0 }]">
            <template #header>
                <Row
                    v-if="mShifts.length > 0"
                    :parentHover="false"
                    :items="{ row: header }"
                    :key="-1"
                    :index="-1"
                    @onClick="args => $emit('onHeaderClick', args)"
                    @onClickColumn="handleHeaderClick"
                    class="Row-Header"
                />
            </template>
            <Row
                v-for="(shift, i) in mShifts"
                :parentHover="false"
                :items="shift"
                :hasClick="true"
                :key="i"
                :index="i"
                :isActive="shift.isActive"
                @onClick="$emit('onRowClick', shift)"
            />
        </RowWrap>
        <Button
            class="Button--LoadMore"
            v-if="mShifts.length >= 30 && mShifts.length < shifts.length"
            @onClick="handleLoadMore"
            >Mehr Laden
        </Button>
    </div>
</template>

<script>
import Row from '@/components/Row';
import RowWrap from '@/components/RowWrap';
import Button from '@/components/widgets/Button';
import { format } from 'date-fns';
import { round2d, euro } from '@/lib/helper';

export default {
    name: 'ItemTable',
    components: {
        Row,
        Button,
        RowWrap,
    },
    props: {
        title: {
            type: String,
        },
        shifts: {
            type: Object,
        },
    },
    data: () => {
        return {
            cursor: 80,
            sortState: {
                column: 'Umsatz (€)',
                ascending: false,
            },
        };
    },
    computed: {
        header() {
            return [
                { name: 'Name', value: null, id: 'name' },
                { name: 'Umsatz (€)', value: null, id: 'income' },
                { name: 'Zeitraum', value: null, id: 'timePeriod' },
            ].map(header => {
                let indicator = '';
                if (this.sortState.column === header.name) {
                    indicator = this.sortState.ascending ? ' ▲' : ' ▼';
                }
                return { ...header, name: header.name + indicator };
            });
        },
        mShifts() {
            const formattedShifts = [...this.formatShifts(this.shifts)].slice(0, this.cursor);
            return formattedShifts.length > 0 ? this.sortShifts(formattedShifts) : [];
        },
    },
    methods: {
        sortShifts(shifts) {
            const { column, ascending } = this.sortState;

            return shifts.sort((a, b) => {
                const getRawValue = shift => {
                    const entry = shift.row.find(item => item.name === column);
                    return entry ? entry.rawValue : undefined;
                };

                const aVal = getRawValue(a);
                const bVal = getRawValue(b);
                if (aVal === undefined && bVal === undefined) return 0;
                if (aVal === undefined) return ascending ? 1 : -1;
                if (bVal === undefined) return ascending ? -1 : 1;

                if (aVal < bVal) return ascending ? -1 : 1;
                if (aVal > bVal) return ascending ? 1 : -1;

                return 0; // When both values are equal
            });
        },
        handleHeaderClick(column) {
            const columnName = column.name
                .replace('▲', '')
                .replace('▼', '')
                .trim();
            if (this.sortState.column === columnName) {
                this.sortState.ascending = !this.sortState.ascending;
            } else {
                this.sortState.column = columnName;
                this.sortState.ascending = true;
            }
        },
        handleLoadMore() {
            this.cursor += 80;
        },
        formatShifts(shifts) {
            const result = [];
            for (const shift of Object.values(shifts)) {
                result.push({
                    ...shift,
                    row: [
                        { name: 'Name', value: shift?.name || '', rawValue: shift?.name },
                        {
                            name: 'Umsatz (€)',
                            value: euro(shift.income).format() + '€',
                            rawValue: shift?.income,
                        },
                        {
                            name: 'Zeitraum',
                            value:
                                format(new Date(shift.timePeriod.start), 'dd.MM.yyyy') +
                                ' - ' +
                                format(new Date(shift.timePeriod.end), 'dd.MM.yyyy'),
                            rawValue: shift?.timePeriod.start,
                        },
                    ],
                });
            }
            return result;
        },
    },
};
</script>

<style lang="scss">
.RevenueTable {
    @media screen and (max-width: 1200px) {
        .Row .Parent {
            width: 300px;
        }
    }
}
</style>
