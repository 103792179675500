var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "rowWrap",
    staticClass: "RowWrap",
    class: [{
      'has-auto-width': _vm.width === 'auto'
    }]
  }, [_c('div', {
    ref: "rowHeader",
    staticClass: "RowHeader-Wrap",
    style: {
      top: _vm.isHeaderFixed ? _vm.fixedHeaderTop + 'px' : ''
    }
  }, [_vm._t("header")], 2), _c('div', {
    ref: "rowContent",
    staticClass: "Row-Content"
  }, [_vm._t("default")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }