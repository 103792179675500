var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h5', [_vm._v(_vm._s(_vm.title))]), _c('RowWrap', {
    class: [{
      'is-empty': _vm.mShifts.length === 0
    }],
    attrs: {
      "width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_vm.mShifts.length > 0 ? _c('Row', {
          key: -1,
          staticClass: "Row-Header",
          attrs: {
            "parentHover": false,
            "items": {
              row: _vm.header
            },
            "index": -1
          },
          on: {
            "onClick": function onClick(args) {
              return _vm.$emit('onHeaderClick', args);
            },
            "onClickColumn": _vm.handleHeaderClick
          }
        }) : _vm._e()];
      },
      proxy: true
    }])
  }, _vm._l(_vm.mShifts, function (shift, i) {
    return _c('Row', {
      key: i,
      attrs: {
        "parentHover": false,
        "items": shift,
        "hasClick": true,
        "index": i,
        "isActive": shift.isActive
      },
      on: {
        "onClick": function onClick($event) {
          return _vm.$emit('onRowClick', shift);
        }
      }
    });
  }), 1), _vm.mShifts.length >= 30 && _vm.mShifts.length < _vm.shifts.length ? _c('Button', {
    staticClass: "Button--LoadMore",
    on: {
      "onClick": _vm.handleLoadMore
    }
  }, [_vm._v("Mehr Laden ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }