var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "RevenueSummaryWrapper"
  }, [_c('div', {
    staticClass: "FormattedRevenue"
  }, [_vm._v(" " + _vm._s(_vm.formattedCurrentRevenue) + " ")]), _c('div', {
    staticClass: "RevenueComparison"
  }, [_vm.hasComparisonRevenue ? _c('i', {
    staticClass: "PercentageIcon",
    class: _vm.percentageChangeClass
  }) : _vm._e(), _c('span', {
    staticClass: "PercentageChange",
    style: _vm.percentageChangeStyle
  }, [_vm._v(" " + _vm._s(_vm.hasComparisonRevenue ? _vm.formattedPercentageChange + '%' : 'N/A') + " ")]), _vm.hasComparisonRevenue ? _c('span', [_vm._v(" vs")]) : _vm._e(), _vm._v("  "), _c('span', {
    staticClass: "FormattedComparisonRevenue"
  }, [_vm._v(" " + _vm._s(_vm.hasComparisonRevenue ? _vm.formattedComparisonRevenue : 'Keine Daten') + " ")]), _vm._v("   "), _vm.hasComparisonRevenue ? _c('span', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip.bottom",
      value: _vm.comparisonPeriodText,
      expression: "comparisonPeriodText",
      modifiers: {
        "bottom": true
      }
    }],
    staticClass: "TooltipIcon"
  }, [_c('i', {
    staticClass: "ri-information-line"
  })]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }