<template>
    <div ref="rowWrap" class="RowWrap" :class="[{ 'has-auto-width': width === 'auto' }]">
        <div
            ref="rowHeader"
            class="RowHeader-Wrap"
            :style="{ top: isHeaderFixed ? fixedHeaderTop + 'px' : '' }"
        >
            <slot name="header"></slot>
        </div>
        <div ref="rowContent" class="Row-Content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RowWrap',
    props: {
        width: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            isHeaderFixed: false,
            initialHeaderOffset: null,
            fixedHeaderTop: 0,
        };
    },
    mounted() {
        if (window.innerWidth >= 1024) {
            return;
        }
        this.initialHeaderOffset = this.$refs.rowHeader.offsetTop;
        const scrollableParent = document.querySelector('.Wrap > span');
        const rowWrap = this.$refs.rowWrap;

        scrollableParent.addEventListener('scroll', this.handleScroll);
        rowWrap.addEventListener('scroll', this.handleRowWrapScroll);
    },
    beforeDestroy() {
        const scrollableParent = document.querySelector('.Wrap > span');
        const rowWrap = this.$refs.rowWrap;

        scrollableParent.removeEventListener('scroll', this.handleScroll);
        rowWrap.removeEventListener('scroll', this.handleRowWrapScroll);
    },
    methods: {
        handleRowWrapScroll() {
            const rowWrap = this.$refs.rowWrap;

            if (this.isHeaderFixed) {
                const leftOffset =
                    this.$refs.rowWrap.getBoundingClientRect().left - rowWrap.scrollLeft;
                this.$refs.rowHeader.style.left = `${leftOffset}px`;
            }
        },
        handleScroll() {
            const rowWrapRect = this.$refs.rowWrap.getBoundingClientRect();

            if (rowWrapRect.top <= 0) {
                if (!this.isHeaderFixed) {
                    this.fixHeader();
                }
            } else {
                this.unfixHeader();
            }
        },
        fixHeader() {
            this.isHeaderFixed = true;
            this.fixedHeaderTop = 0;

            const header = this.$refs.rowHeader;
            header.style.position = 'fixed';
            header.style.top = `${this.fixedHeaderTop}px`;
            header.style.left = `${this.$refs.rowWrap.getBoundingClientRect().left}px`;
            header.style.width = `${this.$refs.rowWrap.offsetWidth}px`;
            header.style.zIndex = '100';

            this.$refs.rowContent.style.paddingTop = `${header.offsetHeight}px`;
        },
        unfixHeader() {
            this.isHeaderFixed = false;
            const header = this.$refs.rowHeader;
            header.style.position = 'static';
            this.$refs.rowContent.style.paddingTop = '0';
        },
    },
};
</script>

<style lang="scss">
.RowWrap {
    width: 100%;
    min-width: max-content;

    &.has-auto-width {
        @media (max-width: 1200px) {
            .Row .Parent {
                width: auto;
            }
        }
    }

    @media (max-width: 1200px) {
        .Row .Parent {
            width: 1100px;
        }
    }

    @media (max-width: 768px) {
        min-width: unset;
        overflow-y: auto;
    }

    &.is-empty {
        > * {
            min-width: 0;
        }
    }

    .RowHeader-Wrap {
        position: sticky;
        top: 0;
        z-index: 10;
    }

    .Row-Header {
        width: 100%;
        background-color: white;
        border-top: solid 1px var(--color-border);

        &.fixed {
            position: fixed;
            top: 0;
            z-index: 101;
            width: 100%;
        }
    }
}
</style>
