<template>
    <div class="RevenueSummaryWrapper">
        <!-- Formatted Current Revenue -->
        <div class="FormattedRevenue">
            {{ formattedCurrentRevenue }}
        </div>

        <div class="RevenueComparison">
            <!-- Conditional rendering of percentage change and arrow icon -->
            <i
                v-if="hasComparisonRevenue"
                :class="percentageChangeClass"
                class="PercentageIcon"
            ></i>

            <!-- Show either percentage change or "No data" if comparison revenue is missing -->
            <span class="PercentageChange" :style="percentageChangeStyle">
                {{ hasComparisonRevenue ? formattedPercentageChange + '%' : 'N/A' }}
            </span>

            <span v-if="hasComparisonRevenue">&nbsp;vs</span>&nbsp;
            <!-- Display comparison revenue or "No data" if there is no revenue -->
            <span class="FormattedComparisonRevenue">
                {{ hasComparisonRevenue ? formattedComparisonRevenue : 'Keine Daten' }}
            </span>

            &nbsp;

            <!-- Tooltip visibility based on the availability of comparison revenue -->
            <span
                class="TooltipIcon"
                v-if="hasComparisonRevenue"
                v-tooltip.bottom="comparisonPeriodText"
            >
                <i class="ri-information-line"></i>
            </span>
        </div>
    </div>
</template>

<script>
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import { priceToEuroString } from '@/lib/helper';

export default {
    name: 'RevenueSummary',
    props: {
        currentRevenue: {
            type: [Number, String],
            required: true,
        },
        comparisonRevenue: {
            type: [Number, String],
        },
        currentStartDate: {
            type: [String, Date],
            required: true,
        },
        currentEndDate: {
            type: [String, Date],
        },
        comparisonStartDate: {
            type: [String, Date],
        },
        comparisonEndDate: {
            type: [String, Date],
        },
    },
    computed: {
        hasComparisonRevenue() {
            // Check if comparison revenue exists and is greater than 0
            return !!this.comparisonRevenue && this.comparisonRevenue > 0;
        },
        formattedCurrentRevenue() {
            return `${this.formatDateRange({
                startDate: this.currentStartDate,
                endDate: this.currentEndDate,
                dateFormat: 'MMMM',
            })} ${priceToEuroString(this.currentRevenue)}`;
        },
        formattedComparisonRevenue() {
            // Format the comparison revenue or show "Keine Daten" if no comparison revenue is available
            return this.hasComparisonRevenue
                ? priceToEuroString(this.comparisonRevenue)
                : 'Keine Daten';
        },
        formattedPercentageChange() {
            if (!this.hasComparisonRevenue) {
                return 'N/A'; // Return 'N/A' when no comparison revenue exists to avoid division by zero
            }
            const diff = this.currentRevenue - this.comparisonRevenue;
            return ((diff / this.comparisonRevenue) * 100).toFixed(1);
        },
        percentageChangeClass() {
            // Only show the comparison arrow when we have valid comparison revenue
            return this.formattedPercentageChange > 0 ? 'ri-arrow-up-line' : 'ri-arrow-down-line';
        },
        percentageChangeStyle() {
            if (!this.hasComparisonRevenue) {
                return { color: 'var(--color-gray)' }; // Grey out the percentage when no comparison revenue
            }
            return {
                color:
                    this.formattedPercentageChange > 0
                        ? 'var(--color-green)'
                        : 'var(--color-yellow-dark)',
            };
        },
        comparisonPeriodText() {
            return `Vergleichszeitraum: ${this.formatDateRange({
                startDate: this.comparisonStartDate,
                endDate: this.comparisonEndDate,
                dateFormat: 'dd.MM.yy',
            })}`;
        },
    },
    methods: {
        formatDateRange({ startDate, endDate, dateFormat = 'dd.MM.yy' }) {
            let dateString = '';
            if (startDate && endDate) {
                const formattedStartDate = format(new Date(startDate), dateFormat, { locale: de });
                const formattedEndDate = format(new Date(endDate), dateFormat, { locale: de });
                dateString = `${formattedStartDate}`;
            } else if (startDate) {
                dateString = format(new Date(startDate), dateFormat, { locale: de });
            }
            return dateString;
        },
    },
};
</script>

<style scoped lang="scss">
.RevenueSummaryWrapper {
    display: flex;
    flex-direction: column;
}

.FormattedRevenue {
    font-size: 1.5rem;
}

.RevenueComparison {
    font-size: 0.75rem;
    display: flex;
    align-items: center;
}

.PercentageIcon {
    margin-right: 5px;
}

.PercentageChange {
    //font-weight: 600;
}

.FormattedComparisonRevenue {
    color: var(--color-text-white);
}

.TooltipIcon {
    font-size: 0.85rem;
    cursor: pointer;
    color: var(--color-text-white);
}

.ri-arrow-up-line {
    color: var(--color-green);
    font-size: 1.25rem;
}

.ri-arrow-down-line {
    color: var(--color-yellow-dark);
    font-size: 1.25rem;
}
</style>
